import clsx from "clsx";
import preval from 'preval.macro';
import Fab from '@mui/material/Fab';
import { useSelector } from "react-redux";
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import ListIcon from '@mui/icons-material/List';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import Drafts from '@mui/icons-material/Drafts';
import klogo from "../../img/ktip_cropped.png";
import NoteIcon from '@mui/icons-material/Note';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { authProvider } from '../../authProvider';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Link, useHistory } from 'react-router-dom';
import ScannerIcon from '@mui/icons-material/Scanner';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CssBaseline from '@mui/material/CssBaseline';
import BugReportIcon from "@mui/icons-material/BugReport";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Persona } from '@fluentui/react/lib/Persona';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FolderIcon from '@mui/icons-material/Folder';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PouchPrint from '../miscellaneous/PouchPrint';
import HelpIcon from '@mui/icons-material/Help';
import Axios from "axios";
import { getBaseURL } from "../../baseURL";

const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  buttonProgressSearch: {
    color: 'white',
    textalign: 'center',
    position: 'absolute',
    marginTop: 6,
    marginLeft: -58
  },
  appBarShift: {
    width: 'calc(100vw - 110px)',
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: "268px",
    borderRight: "none",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    backgroundColor: '#140a9a',
    minHeight: '70px !important;',
    justifyContent: 'space-between',
    // justifyContent: 'flex-end',
  },

  ListDiv: {
    maxheight: 'calc(100vh - 70px)',
    overflow: 'auto',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },

  CrossIcon: {
    fontSize: '35px',
    color: '#fff',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const NavMenu = ({ classes, theme, isOpen, handleSetOpen, currentUser, hasAccess, FeatureControlList, hasFeaturerbrowser, hasEdgeBrowser }) => {
  const [bomAdmin, setBomAdmin] = useState(false);
  const [bomAccess, setBomAccess] = useState(null);
  const [userAdmin, setUserAdmin] = useState(false);
  const [scanAdmin, setScanAdmin] = useState(false);
  const [pouchPrintAccess, setPouchPrintAccess] = useState(false);
  const [emailfiles, setemailfiles] = useState(false);
  const [systemAdmin, setSystemAdmin] = useState(false);
  const currentUserAuth = useSelector(state => state.auth);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const [FaqEmailFiles, setFaqEmailFiles] = useState(false);
  const [loaderFeature, setloaderFeature] = useState(false);
  const [infoIconAnchor, setInfoIconAnchor] = useState(null);
  const [informationOpen, setInformationOpen] = useState(false);
  const [sendgroupemails, setsendgroupemails] = useState(false);
  const [accessRequestCount, setAccessRequestCount] = useState(0);
  const [groupEmailAccess, setGroupEmailAccess] = useState(false);
  const [shouldDisplayFAQ, setshouldDisplayFAQ] = useState(false);
  const [KtipLoaderAccess, setKtipLoaderAccess] = useState(false);
  const [DisplayReportBug, setDisplayReportBug] = useState(false);
  const [manageDraftsAccess, setManageDraftsAccess] = useState(false);
  const [specialAccessAdmin, setSpecialAccessAdmin] = useState(false);
  const [ExportDrawingAccess, setExportDrawingAccess] = useState(false);
  const [DrawingUploadAccess, setDrawingUploadAccess] = useState(false);
  const [ExportDrawingFeature, setExportDrawingFeature] = useState(false);
  const [DrawingUploadFeature, setDrawingUploadFeature] = useState(false);
  const [DisplayRequestFeature, setDisplayRequestFeature] = useState(false);
  const [drawingSummaryFeature, setdrawingSummaryFeature] = useState(false);
  const [BomUserPermissionGiven, setBomUserPermissionGiven] = useState(false);
  const [reportMenuOpen, setReportMenuOpen] = useState(false);
  const currentGraphUser = useSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [anchorE1, setAnchorE1] = useState(null);
  const open = Boolean(anchorE1);

  const [appBarColor, setAppBarColor] = useState("140a9a");

  const config = {
    headers: { 'Authorization': "Bearer " + currentGraphUser.jwtIdToken.idToken },
    'Content-Type': 'application/json'
  };
  let history = useHistory();
  const [items, setItems] = useState([]);
  const fileName = new Set();
  const GetFaqFilesData = (event) => {
    setIsLoading(true);
    Axios.get(`${getBaseURL()}/faq/GetUserMannuals`, config)
      .then(response => {
        setTimeout(() => {
          setIsLoading(true);
          const files = response.data;
          const uniqueFiles = files.filter((file, index, self) =>
            index === self.findIndex((t) => (
              t.fileName === file.fileName
            )));
          setItems(uniqueFiles);
          setIsLoading(false);
          setShowContent(true);
        }, 2000);
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false);
      })
    setAnchorE1(event.currentTarget);
  }

  useEffect(() => {
    if (currentUser.permissions !== undefined) {
      if (currentUserAuth !== undefined) {
        if (FeatureControlList.length > 0) {
          setFaqEmailFiles(FeatureControlList.find(x => x.name == "Faq Email").grantByAdmin)
          setshouldDisplayFAQ(FeatureControlList.find(x => x.name == "FAQ").grantByAdmin)
          setDisplayReportBug(FeatureControlList.find(x => x.name == "Report Bug").grantByAdmin)
          setDisplayRequestFeature(FeatureControlList.find(x => x.name == "Request Feature").grantByAdmin)
          setdrawingSummaryFeature(FeatureControlList.find(x => x.name == "Drawing Summary").grantByAdmin)
          setDrawingUploadFeature(FeatureControlList.find(x => x.name == "Drawing Upload").grantByAdmin)
          setExportDrawingFeature(FeatureControlList.find(x => x.name == "Export Drawings").grantByAdmin)
        }
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "Email Files").value === true) {
          setemailfiles(true);
        }
        else {
          setemailfiles(false);
        }
      }
      catch (error) {
        setemailfiles(false);
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "Send Group Emails").value === true) {
          setsendgroupemails(true);
        }
        else {
          setsendgroupemails(false);
        }
      }
      catch (error) {
        setsendgroupemails(false);
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "BOM Explode Access").value === true) {
          setBomAccess(true);
        }
        else {
          setBomAccess(false);
        }
      }
      catch (error) {
        setBomAccess(false);
      }

      try {
        if (currentUser.permissions.find(p => p.permissionName === "System Admin").value === true) {
          setSystemAdmin(true);
        }
        else {
          setSystemAdmin(false);
        }
      }
      catch (error) {
        setSystemAdmin(false);
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "KTIP Loader").value === true) {
          setKtipLoaderAccess(true);
        }
        else {
          setKtipLoaderAccess(false);
        }
      }
      catch (error) {
        setKtipLoaderAccess(false);
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "Export Drawing").value === true) {
          setExportDrawingAccess(true);
        }
        else {
          setExportDrawingAccess(false);
        }
      }
      catch (error) {
        setExportDrawingAccess(false);
      }
      try {
        if (currentUser.permissions.find(p => p.permissionName === "Drawing Upload").value === true) {
          setDrawingUploadAccess(true);
        }
        else {
          setDrawingUploadAccess(false);
        }
      }
      catch (error) {
        setDrawingUploadAccess(false);
      }
      if (!systemAdmin) {
        try {
          if (currentUser.permissions.find(p => p.permissionName === "BOM Admin").value === true) {
            setBomAdmin(true);
          }
          else {
            setBomAdmin(false);
          }
        }
        catch (error) {
          setBomAdmin(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "User Admin").value === true) {
            setUserAdmin(true);
          }
          else {
            setUserAdmin(false);
          }
        }
        catch (error) {
          setUserAdmin(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "Scan Admin").value === true) {
            setScanAdmin(true);
          }
          else {
            setScanAdmin(false);
          }
        }
        catch (error) {
          setScanAdmin(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "Special Access Admin").value === true) {
            setSpecialAccessAdmin(true);
          }
          else {
            setSpecialAccessAdmin(false);
          }
        }
        catch (error) {
          setSpecialAccessAdmin(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "Manage Group Emails").value === true) {
            setGroupEmailAccess(true);
          }
          else {
            setGroupEmailAccess(false);
          }
        }
        catch (error) {
          setGroupEmailAccess(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "BOM Explode New User Access").value === true) {
            setBomUserPermissionGiven(true);
          }
          else {
            setBomUserPermissionGiven(false);
          }
        }
        catch (error) {
          setBomUserPermissionGiven(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "Manage Drafts Access").value === true) {
            setManageDraftsAccess(true);
          }
          else {
            setManageDraftsAccess(false);
          }
        }
        catch (error) {
          setManageDraftsAccess(false);
        }

        try {
          if (currentUser.permissions.find(p => p.permissionName === "Pouch Print Access").value === true) {
            setPouchPrintAccess(true);
          }
          else {
            setPouchPrintAccess(false);
          }
        }
        catch (error) {
          setPouchPrintAccess(false);
        }
      }
    }
  }, [currentUser]);

  const handleHelpClick = (event, idToRemove) => {
    if (currentUser.permissions.find(p => p.permission === 2).value === true && currentUser.permissions.find(p => p.permission === 8).value === true && currentUser.permissions.find(p => p.permission === 12).value === true) {
      const newArray = [1, 2, 3, 4, 5, 6, 7, 8, 9]
      const updatedItems = items.filter(item => newArray.includes(item.id))
      setItems(updatedItems);
    } else {
      const updatedItems = items.filter(item => !idToRemove.includes(item.id))
      setItems(updatedItems);
    }
    setAnchorE1(event.currentTarget);
  }
  const handleHelpClose = () => {
    setAnchorE1(null);
  };

  const downloadFiles = (e) => {
    Axios.get(`${getBaseURL()}/filedownload/${e}/HelpGuide`, config)
      .then((response) => {
        if (response.data.downloadResponse !== "") {
          const link = document.createElement('a');
          link.href = response.data.downloadResponse;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
        }
      })
      .catch((response) => {
        console.log(JSON.stringify(response));
      })
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
        style={{ backgroundColor: appBarColor }}
      >
        <Toolbar>
          {(hasAccess && hasFeaturerbrowser == false) ?
            <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={() => handleSetOpen(true)}
              edge="start"
              className={clsx(classes.menuButton, isOpen && classes.hide)}
              size="large">
              <MenuIcon />
            </IconButton>
            : (hasAccess && hasFeaturerbrowser && hasEdgeBrowser === false) ? null :
              hasAccess &&
              <IconButton
                color="inherit"
                aria-label="open menu"
                onClick={() => handleSetOpen(true)}
                edge="start"
                className={clsx(classes.menuButton, isOpen && classes.hide)}
                size="large">
                <MenuIcon />
              </IconButton>
          }
          <img src={klogo} alt="logo" height="70px" style={{ cursor: "pointer" }} onClick={() => history.push("/")} />
          <Typography variant="h5" color="inherit" style={{ paddingLeft: "10px", fontSize: '1.25vw' }}>
            Komatsu Technical Intellectual Property
          </Typography>
          {
            window.location.hostname.includes("localhost") &&
            <Typography variant="h5" color="inherit" style={{ paddingLeft: "10px", fontSize: '1.25vw' }}>
              DEV
            </Typography>
          }
          {
            window.location.hostname.includes("kmc-ktip-test.azurewebsites.net") &&
            <Typography variant="h5" color="inherit" style={{ paddingLeft: "10px", fontSize: '1.25vw' }}>
              TEST
            </Typography>
          }
          {currentUserAuth.aadResponse &&
            <Typography variant="h6" style={{ marginLeft: "auto", marginRight: '10px', fontSize: '1vw' }} color="inherit">{currentUserAuth.aadResponse.account.name}</Typography>
          }
          {currentUserAuth.user.photo &&
            <Persona
              imageUrl={"data:image/png;base64," + currentUserAuth.user.photo}
              hidePersonaDetails
            />
          }
          <IconButton
            aria-label="more"
            component="span"
            size="large"
            aria-haspopup="true"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => GetFaqFilesData(event)}
            style={{ paddingLeft: "57%", textAlign: 'right' }}
          >
            {isLoading ? <CircularProgress size={24} className={classes.buttonProgressSearch} /> : <HelpIcon style={{ color: "white" }} />}
          </IconButton>

          <IconButton
            component="span"
            onClick={(event) => { setInfoIconAnchor(event.currentTarget); setInformationOpen(true); console.log(event) }}
            size="large"
          >
            <InfoIcon style={{ color: "white" }} />
          </IconButton>

          {/* Mannual Menu Popup */}

          <Menu
            id="long-menu"
            anchorEl={anchorE1}
            open={showContent && (open)}
            onClose={handleHelpClose}
            style={{ left: '40%' }}
          >
            {items.map(option => (
              <MenuItem key={option.id}>
                <a href="#" onClick={() => downloadFiles(option.id)} style={{ color: "blueviolet", whiteSpace: "pre-line" }}>{option.fileName}</a>
              </MenuItem>
            ))}
            <MenuItem style={{ fontWeight: "600" }}> If the above-mentioned links are not <br></br> of any assistance to you, please contact <br></br></MenuItem>
            <MenuItem ><a style={{ color: "blueviolet", whiteSpace: "pre-line" }} href="mailto:ktip-support-nagm@global.komatsu">ktip-support-nagm@global.komatsu</a></MenuItem>
          </Menu>
          <Popover
            open={informationOpen}
            onClose={() => { setInformationOpen(false); setInfoIconAnchor(null); }}
            anchorEl={infoIconAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="subtitle1">Build Date: {preval`module.exports = new Date().toLocaleString();`}</Typography>
          </Popover>
        </Toolbar>
      </AppBar>
      {hasAccess &&
        <span>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isOpen}
            classes={{ paper: classes.drawerPaper, }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={() => handleSetOpen(false)} size="large">
                <CloseIcon className={classes.CrossIcon} />
              </IconButton>
              <img src={klogo} alt="logo" height="70px" style={{ cursor: "pointer" }} onClick={() => history.push("/")} />

            </div>
            <Divider />
            <div className={classes.ListDiv}>
              <List>
                <ListItem component={Link} button key={1} to='/drawingSearch' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><CreateIcon /></ListItemIcon>
                  <ListItemText>Drawing Search</ListItemText>
                </ListItem>

                <ListItem component={Link} button key={2} to='/multipleiteminquiry' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><SearchIcon /></ListItemIcon>
                  <ListItemText>Multiple Item Inquiry</ListItemText>
                </ListItem>

                <ListItem component={Link} button key={23} to='/editAccess' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><KeyIcon /></ListItemIcon>
                  <ListItemText>Edit Access</ListItemText>
                </ListItem>


                {bomAccess && (!BomUserPermissionGiven || systemAdmin) &&
                  <ListItem component={Link} button key={3} to='/BOMExplode' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><AssignmentIcon /></ListItemIcon>
                    <ListItemText>BOM Explode</ListItemText>
                  </ListItem>
                }
                {!bomAccess && BomUserPermissionGiven &&
                  <ListItem component={Link} button key={4} to='/bomaccessrequest' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><AssignmentIcon /></ListItemIcon>
                    <ListItemText>BOM Explode Access Request</ListItemText>
                  </ListItem>
                }
                {(specialAccessAdmin || manageDraftsAccess) &&
                  <ListItem component={Link} button key={28} to='/managedrafts' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><Drafts /></ListItemIcon>
                    <ListItemText>Manage Drafts</ListItemText>
                  </ListItem>
                }
                <ListItem component={Link} button key={5} to='/scanrequest' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><ScannerIcon /></ListItemIcon>
                  <ListItemText>Scan Request</ListItemText>
                </ListItem>

                <ListItem component={Link} button key={6} to='/specialaccessrequest' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                  <ListItemText>Special Access Requests</ListItemText>
                </ListItem>

                {(pouchPrintAccess || systemAdmin) &&
                  <ListItem component={Link} button key={26} to='/pouchprint' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><ScannerIcon /></ListItemIcon>
                    <ListItemText>Pouch Print</ListItemText>
                  </ListItem>}

                {(groupEmailAccess || systemAdmin) &&
                  <ListItem component={Link} button key={7} to='/manageemailgroups' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><EmailIcon /></ListItemIcon>
                    <ListItemText>Manage Email Groups</ListItemText>
                  </ListItem>
                }
                {(shouldDisplayFAQ === true) &&
                  <ListItem component={Link} button key={8} to='/faq' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                    <ListItemText>FAQ </ListItemText>
                  </ListItem>
                }


                {/* {(ExportDrawingFeature) &&
                  <ListItem component={Link} button key={10} to='/DrawingExport' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><ImportExportIcon /></ListItemIcon>
                    <ListItemText>Export Drawings</ListItemText>
                  </ListItem>

                } */}
                {(DrawingUploadAccess) &&
                  <ListItem component={Link} button key={11} to='/DrawingUpload' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><CloudUploadIcon /></ListItemIcon>
                    <ListItemText>Drawing Upload</ListItemText>
                  </ListItem>
                }

                {(KtipLoaderAccess) &&

                  <ListItem component={Link} button key={12} to='/ktipLoader' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><ImportContactsIcon /></ListItemIcon>
                    <ListItemText>Ktip Loader</ListItemText>
                  </ListItem>
                }
                {(KtipLoaderAccess) &&

                  <ListItem component={Link} button key={13} to='/drawingsummary' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><ListIcon /></ListItemIcon>
                    <ListItemText>Drawing Summary</ListItemText>
                  </ListItem>

                }
                {/* {DisplayReportBug &&
                  <ListItem component={Link} button key={14} to='/BugReport' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><BugReportIcon /></ListItemIcon>
                    <ListItemText>Report a Bug</ListItemText>
                  </ListItem>
                }
                {DisplayRequestFeature &&
                  <ListItem component={Link} button key={15} to='/FeatureRequest' color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><EmojiObjectsIcon /></ListItemIcon>
                    <ListItemText>Request a Feature</ListItemText>
                  </ListItem>} */}

                {(systemAdmin || userAdmin || scanAdmin || bomAdmin || specialAccessAdmin) &&
                  <ListItem button key={16} color="primary" onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
                    <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                    <ListItemText>Administration</ListItemText>
                    {adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                }

              </List>
              <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
                <List>
                  {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={17} to='/ManageUserRights' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                      <ListItemText>User Rights</ListItemText>
                    </ListItem>
                  }
                  {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={18} to='/manageaccessrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                      <ListItemText>Access Requests</ListItemText>
                    </ListItem>
                  }
                  {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={19} to='/auditreport' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
                      <ListItemText>Audit Report</ListItemText>
                    </ListItem>
                  }
                  {(scanAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={20} to='/managescanrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><NoteIcon /></ListItemIcon>
                      <ListItemText>Scan Requests</ListItemText>
                    </ListItem>
                  }
                  {(bomAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={21} to='/managebomaccessrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><AccountTreeIcon /></ListItemIcon>
                      <ListItemText>BOM Requests</ListItemText>
                    </ListItem>
                  }
                  {(specialAccessAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={22} to='/managespecialaccessrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><AvTimerIcon /></ListItemIcon>
                      <ListItemText>Special Access Requests</ListItemText>
                    </ListItem>
                  }
                  {/* {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={23} to='/emailedfilesreport' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><EmailIcon /></ListItemIcon>
                      <ListItemText>Emailed Files</ListItemText>
                    </ListItem>
                  } */}
                  {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={24} to='/managefaq' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                      <ListItemText>Add Questions/Answers to FAQ</ListItemText>
                    </ListItem>
                  }
                  <ListItem component={Link} button key={27} to='/inactiveusers' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                    <ListItemText>Inactive Users Report</ListItemText>
                  </ListItem>
                  {(userAdmin || systemAdmin) &&
                    <ListItem component={Link} button key={25} to='/managefeaturecontrol' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                      <ListItemText>Manage Feature Control</ListItemText>
                    </ListItem>
                  }
                  {(groupEmailAccess || emailfiles || sendgroupemails) && (FaqEmailFiles === true) &&
                    <ListItem component={Link} button key={9} to='/faqfiles' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                      <ListItemText>FAQ Files for Email Recipient(s).</ListItemText>
                    </ListItem>
                  }
                  {(groupEmailAccess || emailfiles || sendgroupemails) && (FaqEmailFiles === true) &&
                    <ListItem component={Link} button key={9} to='/helpLibrary' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                      <ListItemText>Help Library</ListItemText>
                    </ListItem>
                  }
                </List>
              </Collapse>
              <Divider />
              {(systemAdmin || userAdmin) &&
                <ListItem button key={16} color="primary" onClick={() => setReportMenuOpen(!reportMenuOpen)}>
                  <ListItemIcon><FolderIcon /></ListItemIcon>
                  <ListItemText>Reports</ListItemText>
                  {reportMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              }
              <Collapse in={reportMenuOpen} timeout="auto" unmountOnExit>
                {(userAdmin || systemAdmin) &&
                  <ListItem component={Link} button key={25} to='/reports/activeusers' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                    <ListItemText>Active Users</ListItemText>
                  </ListItem>
                }
              </Collapse>
            </div>
          </Drawer>
        </span>
      }
      {/* {DisplayReportBug &&
        <Fab color="primary" aria-label="bugReport" style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 99 }} component={Link} key={15} to='/BugReport' onClick={() => handleSetOpen(false)}>
          <BugReportIcon />
        </Fab>} */}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(NavMenu);