import Axios from "axios";
import Grid from '@mui/material/Grid';
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { useHistory } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { getPdfFiles, ViewPdfFiles } from '../../actions/actions';
import FormGroup from '@mui/material/FormGroup';
import makeStyles from '@mui/styles/makeStyles';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { EmailFiles } from "../miscellaneous/EmailFiles";
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, TextField, Button, Snackbar } from "@mui/material";

export const MultipleItemInquiry = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2)
    },
    paperSpacing: {
      paddingTop: "30px"
    },
    wrapper: {
      position: 'relative',
      marginLeft: '10px'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700]
      }
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12
    },
    buttonProgressDownload: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12
    },
    paperSpacing: {
      paddingTop: "30px"
    },
    helperText: {
      marginLeft: 0
    },
    buttonProgressSearch: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -3,
      marginLeft: -9
    },
    gridHeading: {
      fontWeight: 550
    },
    errorStyle: {
      display: "block",
      paddingTop: 10
    }
  }));

  const classes = useStyles();
  const [drawings, setDrawings] = useState({});
  const [AlertColor, setAlertColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [SnackBarText, setSnackBarText] = useState("");
  const [documentNames, setDocumentNames] = useState("");
  const [exactResults, setExactResults] = useState(false);
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [downloadAllDisabled, setDownloadAllDisabled] = useState(true);
  const [downloadAllDowloading, setDownloadAllDownloading] = useState(false);
  const [documentNameError, setDocumentNameError] = useState({ Error: false, Text: "" });
  const [outsideDrawingsError, setOutsideDrawingsError] = useState({ error: false, text: "" });

  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
  };

  let history = useHistory();
  const onLatestChanged = event => {
    setExactResults(event.target.checked);
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (drawings && drawings.length > 0) {
      if (drawings.filter(x => x.isChecked === true).length > 0) {
        if (drawings.filter(x => x.isDownloading === true).length > 0) {
          setDownloadAllDisabled(true);
        }
        else {
          setDownloadAllDisabled(false);
        }
      }
      else {
        setDownloadAllDisabled(true);
        setSelectAllChecked(false);
      }
    }
  }, []);

  const onDocumentNamesChange = event => {
    setDocumentNames(event.target.value);

    if (event.target.value) {
      setDocumentNameError({ Error: false, Text: "" });
    }
  }

  const onSubmit = () => {
    if (documentNames) {
      setSelectAllChecked(false);
      setIsLoading(true);
      setDocumentNameError({ Error: false, Text: "" });
      setOutsideDrawingsError({Error: false, Text: ""});
      var document = {
        DrawingNumber: documentNames,
        ExactResults:exactResults
      }

      Axios.post(`${getBaseURL()}/documents/multipleiteminquiry`, document, config)
        .then((response) => {   
          let outsideClassificationLevelDrawings = response.data.filter(x => x.classificationLevel > currentUser.user.classification.classLevel && x.hasSpecialAccess == false);
          if(outsideClassificationLevelDrawings.length > 0){
            setOutsideDrawingsError({Error: true, Text: "This is a restricted access item and you must use the KTIP Special Access request process to view this item."});
          }
          else{
            setOutsideDrawingsError({Error: false, Text: ""});
          }
          setDrawings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setDocumentNameError({ Error: true, Text: error.response.data.message });
          setIsLoading(false);
        });
    }
    else {
      setDocumentNameError({ Error: true, Text: "Enter Document Name(s)" });
    }
  }

  const onCheckAllChange = event => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.classificationLevel != null && x.documentCount && x.documentCount > 0 && x.byteArray != null && x.id.length !== 0 && x.id !== 0).forEach(x => x.isChecked = event.target.checked);

    setDrawings(drawingsArray);

    var userDrawings = [...drawingsArray].filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.classificationLevel != null && x.documentCount && x.documentCount > 0 && x.byteArray != null);
    if (userDrawings.filter(x => x.isChecked).length)
      setDownloadAllDisabled(false)
    else
      setDownloadAllDisabled(true)

    if (userDrawings.length > 0 && userDrawings.filter(x => x.isChecked).length === userDrawings.length)
      setSelectAllChecked(true);
    else
      setSelectAllChecked(false);
  }

  const onCheckedChange = event => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.classificationLevel != null && x.documentCount && x.documentCount > 0 && x.byteArray != null && x.id == event.target.id).forEach(x => x.isChecked = event.target.checked);

    setDrawings(drawingsArray);

    var userDrawings = [...drawingsArray].filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.classificationLevel != null && x.documentCount && x.documentCount > 0 && x.byteArray != null);
    if (userDrawings.filter(x => x.isChecked).length)
      setDownloadAllDisabled(false)
    else
      setDownloadAllDisabled(true)

    if (userDrawings.length > 0 && userDrawings.filter(x => x.isChecked).length === userDrawings.length) {
      setSelectAllChecked(true);
    }
    else {
      setSelectAllChecked(false);
    }
  }

  const handleDownloadClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      });
  }

  const handleViewClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    ViewPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
    .then((response) => {
      drawingsArray = [...drawings];
      drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
      drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
      setDrawings(drawingsArray);
    })
    .catch((error) => {
      drawingsArray = [...drawings];
      drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
      drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
      setDrawings(drawingsArray);
      setAlertColor("error");
      setSnackBarText(error.response.data);
      setSnackBarOpen(true);
    });
  }

  const downloadAllClick = () => {
    if (drawings.filter(x => x.isChecked).length > 0) {
      setDownloadAllDownloading(true);
      getPdfFiles(drawings, currentUser.jwtIdToken.idToken)
        .then((response) => {
          var drawingsArray = [...drawings];
          drawingsArray.forEach(x => x.isChecked = false);
          setDownloadAllDownloading(false);
        })
        .catch((error) => {
          setDownloadAllDownloading(false);
        })
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }

  const handleNullClassificationAccessRequest = (drawingId, drawingNumber) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = true);
    setDrawings(drawingsArray);
    Axios.get(`${getBaseURL()}/requests/SpecialAccessRequestForEmptyClassification/${drawingNumber}`, config)
    .then((response) => {
      drawingsArray = [...drawings];
      drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = false);
      setDrawings(drawingsArray);
      setAlertColor("success");
      setSnackBarOpen(true);
      setSnackBarText("An email has been sent to the administration to access this drawing.");
    })
    .catch((error) => {
      setAlertColor("")
      setSnackBarOpen(true);
      setSnackBarText("Error while sending access request email!");
    })
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Typography variant="h4" gutterBottom>Multiple Item Inquiry</Typography>
      <Paper className={classes.root}>
        <Typography variant="subtitle1">Separate multiple documents with commas or by putting each document on a new line.</Typography>
        <TextField
          id="documentNames"
          label="Documents"
          multiline
          fullWidth
          margin="normal"
          variant="outlined"
          rows="10"
          value={documentNames}
          onChange={onDocumentNamesChange}
          error={documentNameError.Error}
          helperText={documentNameError.Error ? documentNameError.Text : null}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
        />
        <FormGroup row>
          <FormControlLabel control={<Checkbox checked={exactResults} color="primary" onChange={onLatestChanged} />} label="Exact Results" />
        </FormGroup>
        <span className={classes.wrapper}>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: "15px" }}
            disabled={isLoading}
            onClick={onSubmit}
          >
            <SearchIcon />&nbsp;Search
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgressSearch} />}
        </span>
        {outsideDrawingsError.Error ? <Typography variant="caption" color="error" className={classes.errorStyle}>{outsideDrawingsError.Text}</Typography> : null}
      </Paper>
      <br />
      <br />
      {(drawings.length > 0 && !isLoading && !documentNameError.Error) ?
        <span>
          <Paper className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <span className={classes.wrapper}>
                  <Button
                    id="downloadAll"
                    variant="contained"
                    color="primary"
                    disabled={downloadAllDisabled}
                    style={{ marginTop: "25%" }}
                    onClick={downloadAllClick}
                  >
                    {downloadAllDowloading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    Download
                  </Button>
                </span>
              </Grid>
              {currentUser.user.permissions.filter(p => p.permissionName === "Email Files" && p.value === true).length > 0 &&
                <Grid item xs={11}>
                  <EmailFiles drawings={drawings.filter(x => x.isChecked)} setDocumentNames={setDocumentNames} setDrawings={setDrawings}/>
                </Grid>
              }
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Checkbox id={"selectAll"} checked={selectAllChecked} onChange={onCheckAllChange} color="primary" /></TableCell>
                  <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                  <TableCell className={classes.gridHeading}>Revision</TableCell>
                  <TableCell className={classes.gridHeading}>Description</TableCell>
                  <TableCell className={classes.gridHeading}>Model</TableCell>
                  <TableCell className={classes.gridHeading}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drawings.filter(d => d.id !== 0).map((item, index) => {
                  return (
                    <TableRow key={index.toString()}>
                      <TableCell><Checkbox id={item.id.toString()} checked={item.isChecked} color="primary" onChange={onCheckedChange} disabled={(item.classificationLevel > currentUser.user.classification.classLevel && !item.hasSpecialAccess)} /></TableCell>
                      <TableCell>{item.drawingNumber}</TableCell>
                      <TableCell>{item.revision}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.model}</TableCell>
                      {item.classificationLevel != null ?
                        (item.classificationLevel <= currentUser.user.classification.classLevel || item.hasSpecialAccess) ?
                          (item.documentCount && item.documentCount > 0 ) ?
                            <TableCell>
                              <span className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleDownloadClick(item.id)}
                                  disabled={item.isDownloading}
                                >
                                  Download
                                </Button>
                                {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                              </span>
                              {(currentUser.user.permissions.some(p => p.permissionName === "View Button") && currentUser.user.permissions.find(p => p.permissionName === "View Button").value === true) &&
                              <span className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleViewClick(item.id)}
                                  disabled={item.isDownloading}
                                >
                                  View
                                </Button>
                                {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                              </span>
                            }
                            </TableCell>
                            :
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                              >
                                Scan Request
                              </Button>
                            </TableCell>
                          : <TableCell>
                            <Button color="primary" variant="contained" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                          </TableCell>
                        : <TableCell></TableCell>
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {(drawings.filter(x => x.id === 0).length > 0) ?
            <span>
              <Typography variant="h5" gutterBottom style={{ paddingTop: '10px' }}>Drawings Not Found</Typography>
              <Paper className={classes.root}>
                <Table>
                  <TableBody>
                    {drawings.filter(d => d.id === 0).map((item, index) => {
                      return (
                        <TableRow key={index.toString()}>
                          <TableCell>{item.drawingNumber}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper></span>
            : null}
        </span>
        : null}
      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : (AlertColor === "info" ? "info" : "error")}>
          {SnackBarText}
        </Alert>
      </Snackbar>
    </Container>
  );
}