import React, { useState, useEffect } from "react";
import { Container, Button, TablePagination, Box, Grid, FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles';
import { getBaseURL } from "../../baseURL";
import { useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import axios from "axios";

export const HelpLibrary = () => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
    }));

    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const [faqFiles, setFaqFiles] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [SnackBarText, setSnackBarText] = useState("");
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [AlertColor, setAlertColor] = useState("");

    const [results, setResults] = useState([]);
    const [selectedVal, setSelectedVal] = useState('GeneralUser');
    const [error, setError] = useState({ Error: false, Text: "" });

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        'Content-Type': 'application/json'
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const deleteFile = (id) => {
        const faqdata = {
            FileId: id
        }
        Axios.post(`${getBaseURL()}/faq/DeleteFaqFile/HelpGuide`, faqdata, config)
            .then((response) => {
                if (response.data.message === "Deleted Successfully") {
                    const newFaqFilesList = faqFiles.filter((item) => item.id !== id);
                    setFaqFiles(newFaqFilesList);
                    setPage(0);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };
    const downloadFile = (fileId) => {
        var filesArray = [...faqFiles];
        if (!filesArray.find(x => x.id === fileId).downloading) {
            filesArray.find(x => x.id === fileId).downloading = true;
            setFaqFiles(filesArray);
            Axios.get(`${getBaseURL()}/filedownload/${fileId}/HelpGuide`, config)
                .then((response) => {
                    if (response.data.downloadResponse !== "") {
                        const link = document.createElement('a');
                        link.href = response.data.downloadResponse;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                    }
                    var filesArray = [...faqFiles];
                    filesArray.find(x => x.id === fileId).downloading = false;
                    console.log(JSON.stringify(response));
                    setFaqFiles(filesArray);
                })
                .catch((response) => {
                    console.log(JSON.stringify(response));
                    var filesArray = [...faqFiles];
                    filesArray.find(x => x.id === fileId).downloading = false;
                    setFaqFiles(filesArray);
                })
        }
        else {
        }
    };

    useEffect(() => {
        onSearchClick();
        GetFaqFilesData();
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const GetFaqFilesData = () => {
        Axios.get(`${getBaseURL()}/faq/GetUploadedFiles`, config)
            .then(response => {
                const files = response.data.filter(item => item.fileDesc !== "FAQ");
                //const files = response.data;
                setFaqFiles(files);
                console.log("faqFiles.length: " + faqFiles.length);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getFileContext = async (e) => {
        const file = e.target.files[0];
        try {
            const formData = new FormData();
            formData.append("FileData", file);
            formData.append("FileDesc", selectedVal);
            setIsLoading(true)
            e.target.value = null;
            await Axios.post(`${getBaseURL()}/FaqFile/Upload/HelpGuid`, formData, config)
                .then((response) => {
                    if (response.data.message === "File is uploaded Successfully") {
                        setIsLoading(false)
                        setAlertColor("success")
                        GetFaqFilesData();
                        setSnackBarText(file.name + " is Uploaded successfully!");
                        setSnackBarOpen(true);
                    } else {
                        setSnackBarText(response.data.message + "Unable to Upload the file please try again");
                        setSnackBarOpen(true);
                    }
                })
                .catch((error) => {
                    e.target.value = null;
                    console.log(error);
                    setSnackBarText("An error occured Please try again");
                })
        } catch (error) {
            e.target.value = null;
            console.log(error);
        }
    }

    const onSearchClick = () => {
        const User = {
            guid: currentUser.user.guid
        }
        axios.post(`${getBaseURL()}/admin/manageuserrights`, User, config)
            .then(res => {
                setResults(res.data.permissions);
                console.log(res.data);
            })
            .catch(error => {
                setError({ Error: true, Text: error.response.data.message });
            });
    };

    const handleChange = (event) => {
        setSelectedVal(event.target.value.split(/\s+/).join(''));
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant="h4" gutterBottom>Upload Section </Typography>
            <br />
            <Box>
                <Grid item xs={6}>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl required fullWidth size="small">
                            <Select
                                id="demo-simple-select"
                                onChange={handleChange}
                                defaultValue={selectedVal}
                            >
                                <MenuItem value="GeneralUser">
                                    General User
                                </MenuItem>
                                {results.map(item => (
                                    <MenuItem value={item.permissionName}  >
                                        {item.permissionName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>*required</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <br />
                <Grid item xs={6}>
                    <React.Fragment>
                        <input type="file" id="inputFile" className="form-control-file inputfile" onChange={getFileContext} data-title="Select File or Drag drop a file" />

                        {isLoading && <LinearProgress color="primary" />}
                    </React.Fragment>
                </Grid>
            </Box>

            <br />
            <br />
            {(faqFiles.length && !loading) > 0 ?
                <Paper className={classes.paperSpacing} p={5}>
                    {/* <Typography variant="h4" gutterBottom>User Manuals</Typography> */}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Uploaded Date</TableCell>
                                <TableCell>User Category</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faqFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.fileName}</TableCell>
                                        <TableCell>{item.mimeType}</TableCell>
                                        <TableCell>{new Date(item.dateUploaded).toLocaleDateString()}</TableCell>
                                        <TableCell>{item.fileDesc}</TableCell>
                                        <TableCell>
                                            {item.downloading ? <CircularProgress size={24} /> :
                                                <Button
                                                    id="btndelete"
                                                    color="primary"
                                                    onClick={() => downloadFile(item.id)}
                                                >
                                                    <CloudDownloadIcon />
                                                </Button>
                                            }
                                            <Button
                                                id="btndelete"
                                                color="primary"
                                                onClick={() => deleteFile(item.id)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={faqFiles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                : null
            }
            <Snackbar
                open={SnackBarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={SnackBarText}
            >
                {/* <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                    {SnackBarText}
                </Alert> */}
            </Snackbar>
        </Container>
    );
}